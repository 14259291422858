import React from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

const DefaultLayout = props => (
  <div>
    <AppBar position="static" color="primary">
      <Toolbar>
        <Typography variant="h6" color="inherit">
          PARKPARK
        </Typography>
      </Toolbar>
    </AppBar>
    {props.children}
  </div>
);

export default DefaultLayout;
