import firebase from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyDPZz0k1CV3Nhic8PpgEQy9RvX5NQ8PHWY",
  authDomain: "parkpark-dk.firebaseapp.com",
  databaseURL: "https://parkpark-dk.firebaseio.com",
  projectId: "parkpark-dk",
  storageBucket: "parkpark-dk.appspot.com",
  messagingSenderId: "726753026218"
};

firebase.initializeApp(config);

export default firebase;
