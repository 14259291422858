import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "../../layouts/default";

export default class HomePage extends React.Component {
  render() {
    return (
      <DefaultLayout>
        <p>Logged in</p>
      </DefaultLayout>
    );
  }
}
