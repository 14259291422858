// Purpose provide authentication to the App regardless of where you're coming form

import React from "react";
import PropTypes from "prop-types";

const AuthContext = React.createContext();

// In the AuthProvider we need to restore any existing sessions and provide actions to the rest of the app.
class AuthProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    firebase: PropTypes.object.isRequired // instance of the firebase, we assume it's initialised
  };

  state = {
    isUserLoggedIn: false, // initial state is unknown,
    userId: null,
    isAccountLoggedIn: false,
    availableAccounts: [],
    selectedAccountId: null,
    accessToken: null
  };

  async componentDidMount() {
    // Verify tokens
    // Subscribe to Firebase Auth state
    this.unsubscribeFirebase = this.props.firebase
      .auth()
      .onAuthStateChanged(this.handleAuthChange);
    // Refresh token
    // Save new token
  }

  componentWillUnmount() {
    // Unsubscribe from Firebase Auth state
    this.unsubscribeFirebase();
  }

  handleAuthChange = async auth => {
    const { firebase } = this.props;
    console.debug(auth);
    // Not logged in
    if (!auth) {
      console.debug("not logged in");
      this.setState({
        isUserLoggedIn: false,
        userId: null,
        selectedAccountId: null
      });
      return;
    }
    // Logged in
    console.log("logged in");
    const idToken = await firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true);
    this.setState({
      isUserLoggedIn: true,
      userId: auth.uid,
      accessToken: idToken
    });
  };

  login = async (email, password) => {
    const { firebase } = this.props;
    return firebase // we let the errors bubble up towards the login screen
      .auth()
      .signInWithEmailAndPassword(email, password);
  };

  loginWithFacebook = async () => {
    const { firebase } = this.props;
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
    // get fb auth token
  };
  logout = async () => {
    const { firebase } = this.props;
    firebase.auth().signOut();
  };

  selectAccount = id => {
    // TODO: Store this selection somewhere
    this.setState({ selectedAccountId: id, isAccountLoggedIn: true });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          onLogin: this.login, // handlers
          onFacebookLogin: this.loginWithFacebook,
          onLogout: this.logout,
          onSelectAccount: this.selectAccount
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthWrapper = ({ renderAccount, renderUser, renderLogin }) => (
  <div>
    <AuthContext.Consumer>
      {authProps => {
        let renderMethod;
        if (authProps.isUserLoggedIn) {
          if (authProps.isAccountLoggedIn) {
            renderMethod = renderAccount;
          } else {
            renderMethod = renderUser;
          }
        } else {
          renderMethod = renderLogin;
        }
        if (!renderMethod || typeof renderMethod !== "function") {
          console.error(
            "One of the render methods is undefined or not a function"
          );
        }
        return renderMethod({ authProps });
      }}
    </AuthContext.Consumer>
  </div>
);

AuthWrapper.propTypes = {
  renderUser: PropTypes.func.isRequired, // Renders the account-picker
  renderAccount: PropTypes.func.isRequired, // Renders a selected account
  renderLogin: PropTypes.func.isRequired
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthWrapper };
