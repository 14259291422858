import React, { Component } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { AuthWrapper, AuthProvider } from "./components/firebase-auth-context";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import firebase from "./firebase";

import "./App.css";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

import LoginPage from "./pages/login";
import AccountSelectionPage from "./pages/account-selection";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import HomePage from "./pages/home";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/signedIn",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false
  }
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: "#b6e2eb",
      main: "#00bcd4",
      dark: "#046065",
      contrastText: "#fff"
    },
    secondary: {
      main: "#f44336"
    }
  }
});

class App extends Component {
  state = {
    isUserLoggedIn: false
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(user => this.setState({ isUserLoggedIn: !!user }));
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  renderLoginWindow = () => (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  );

  renderLoggedIn = () => (
    <div>
      <p>Hurray!</p>
      <button
        onClick={async () => {
          await firebase.auth().signOut();
        }}
      >
        Logout
      </button>
    </div>
  );

  graphqlClient = accessToken => {
    const httpLink = createHttpLink({
      uri:
        process.env.NODE_ENV === "development"
          ? "http://localhost/graphql/"
          : "https://staging.pbilling.dk/graphql/" // change to prod when ready
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer Firebase|${accessToken}` : ""
        }
      };
    });
    const client = new ApolloClient({
      cache: new InMemoryCache(),
      link: authLink.concat(httpLink)
    });
    return client;
  };

  render() {
    return (
      <AuthProvider firebase={firebase}>
        <MuiThemeProvider theme={theme}>
          <AuthWrapper
            renderLogin={({ authProps }) => <LoginPage {...authProps} />}
            renderUser={({ authProps }) => (
              <React.Fragment>
                <ApolloProvider
                  client={this.graphqlClient(authProps.accessToken)}
                >
                  <AccountSelectionPage {...authProps} />
                </ApolloProvider>
              </React.Fragment>
            )}
            renderAccount={() => <HomePage />}
          />
        </MuiThemeProvider>
      </AuthProvider>
    );
  }
}

export default App;
