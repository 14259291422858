import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockIcon from "@material-ui/icons/LockOutlined";
import UserIcon from "@material-ui/icons/Face";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import { graphql } from "react-apollo";
import gql from "graphql-tag";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import LoginLayout from "../../layouts/login";

class AccountSelectionPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onLogin: PropTypes.func.isRequired,
    onFacebookLogin: PropTypes.func.isRequired,
    onSelectAccount: PropTypes.func.isRequired
  };

  handleLogout = async e => {
    try {
      await this.props.onLogout();
    } catch (err) {
      alert(err);
    }
  };

  handleAccountSelect = async id => {
    try {
      await this.props.onSelectAccount(id);
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const {
      data: { loading, error, user }
    } = this.props;
    if (error) {
      return alert(error);
    }
    if (loading) {
      return (
        <LoginLayout>
          <CircularProgress />
        </LoginLayout>
      );
    }
    console.log(this.props.data);
    const { classes } = this.props;
    return (
      <LoginLayout>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Select Account
        </Typography>
        <br />
        {user.accounts && user.accounts.length > 0 ? (
          <List>
            {user.accounts.map(a => (
              <ListItem key={a.id} onClick={this.handleAccountSelect}>
                <Avatar className={classes.avatar}>
                  <UserIcon />
                </Avatar>
                <ListItemText>{a.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        ) : (
          <React.Fragment>
            <p>No accounts found.</p>
            <p>Please contact our customer-service.</p>
            <p>Reference: {user.id}</p>
          </React.Fragment>
        )}
        <Button onClick={this.handleLogout}>Logout</Button>
      </LoginLayout>
    );
  }
}

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

const query = gql`
  {
    user {
      id
      expires
      accounts {
        id
        name
      }
    }
  }
`;

const withData = graphql(query);

AccountSelectionPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withData(withStyles(styles)(AccountSelectionPage));
